input:focus, textarea:focus, select:focus {
    outline: none;
}

body {
    background: linear-gradient(331deg, #f3933f, #1e3d79, #14b2e8, #f3933f);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 11s ease infinite;
    -moz-animation: AnimationName 11s ease infinite;
    -o-animation: AnimationName 11s ease infinite;
    animation: AnimationName 11s ease infinite;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 40%
    }

    50% {
        background-position: 100% 61%
    }

    100% {
        background-position: 0% 40%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 40%
    }

    50% {
        background-position: 100% 61%
    }

    100% {
        background-position: 0% 40%
    }
}

@-o-keyframes AnimationName {
    0% {
        background-position: 0% 40%
    }

    50% {
        background-position: 100% 61%
    }

    100% {
        background-position: 0% 40%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 40%
    }

    50% {
        background-position: 100% 61%
    }

    100% {
        background-position: 0% 40%
    }
}